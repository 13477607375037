import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Menu = Loadable(lazy(() => import('./Menu')));

const MoiRoutes = [
  { path: '/moi/:printerId', element: <Menu/> },  
];
    
export default MoiRoutes;
