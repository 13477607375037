import { H1 } from 'app/components/Typography'
import React from 'react'

const Hero = () => {
  return (
    <div>
      <H1>asdas</H1>
    </div>
  )
}

export default Hero
