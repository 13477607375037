import React, { useState, useEffect } from "react";
import { Box, Button, Link } from "@mui/material";

const FileInput = ({ url, handleChange, label, key }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);
  console.log(key);
  return (
    <>
      <input
        accept="image/*, application/pdf"
        type="file"
        id={`select-image-${label}`}
        style={{ display: "none" }}
        onChange={(e) => {
          setSelectedImage(e.target.files[0]);
          handleChange(e.target.files[0]);
        }}
      />
      <label htmlFor={`select-image-${label}`}>
        <Button
          variant="contained"
          color="secondary"
          component="span"
        >
          Upload {label}
        </Button>
      </label>
      {(imageUrl && selectedImage) && (
        <Box mt={2} textAlign="center">
          <div>Image Preview:</div>
          {imageUrl.indexOf(".pdf") !== -1 ? (
            <h1>PDF Preview</h1>
          ) : (
            <img src={imageUrl} alt={selectedImage.name} height="100px" />
          )}
        </Box>
      )}
      {(url) && !(url instanceof File) && !(imageUrl && selectedImage) && (
        <Box mt={2} textAlign="center">
          <div>Image Preview:</div>
          {url.indexOf(".pdf") !== -1 ? (
            <Link target="_blank" href={url}>
              View Pdf
            </Link>
          ) : (
            <img src={url} alt={'form image'} height="100px" />
          )}
        </Box>
      )}
    </>
  );
};

export default FileInput;
