import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import enTranslations from '../../utils/en';
import arTranslations from '../../utils/ar';
import { LanguageContext } from 'app/contexts/LanguageContext';
import { H1 } from 'app/components/Typography';


// inital login credentials
const initialValues = {
    printerId: '72071599',
    count: 1,
    color: 'gray-scale',
    file: [],
};


const PrintOptionWithTimestamp = () => {
    const { printerId, timestamp } = useParams();
    const [loading, setLoading] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const navigate = useNavigate();
    const { language } = useContext(LanguageContext);
    const translations = (language == 'en') ? enTranslations : arTranslations;

    useEffect(() => {
        var now = Math.round(new Date() / 1000);;
        var past = new Date(timestamp);
        console.log(now + ' ' + timestamp);
        setIsExpired(now > timestamp);
    }, []);

    const handleFormSubmit = (values, { setFieldError }) => {
        setLoading(true);


        console.log('last false');
        // setLoading(false);
    };
    if (!isExpired) {

        return (
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid
                        item
                        sx={{
                            width: { xs: '50%', md: '25%' },
                            textAlign: 'center'
                        }}
                    >

                        <Box
                            component="img"
                            sx={{
                                mr: 1,
                                width: { xs: 106, md: 230 },
                            }}
                            onClick={() => {
                                navigate(`/print-automatic/${printerId}`)
                            }}
                            alt="print"
                            src="/assets/images/print.png"
                        />
                        <Typography
                            noWrap
                            component="h6"
                            // href="/"
                            sx={{
                                fontFamily: 'monospace',
                                fontSize: { sx: 14, md: 24 },
                                fontWeight: 900,
                                color: 'black',
                                textDecoration: 'none',
                                justifyContent: 'center',
                                textAlign: 'center'
                            }}
                        >
                            {translations.print}
                        </Typography>
                    </Grid>
                    <Grid item sx={{
                        width: { xs: '50%', md: '25%' },
                        textAlign: 'center'
                    }}>

                        <Box
                            component="img"
                            sx={{
                                mr: 1,
                                width: { xs: 106, md: 230 },
                            }}
                            onClick={() => {
                                navigate(`/print-static/${printerId}`)
                            }}
                            alt="print"
                            src="/assets/images/edit_form.png"
                        />
                        <Typography
                            noWrap
                            component="h6"
                            // href="/"
                            sx={{
                                fontFamily: 'monospace',
                                fontSize: { sx: 14, md: 24 },
                                fontWeight: 900,
                                color: 'black',
                                textDecoration: 'none',
                                justifyContent: 'center',
                                textAlign: 'center'
                            }}
                        >
                            {translations.edit_form}
                        </Typography>
                    </Grid>
                    <Grid item sx={{
                        width: { xs: '50%', md: '25%' },
                        textAlign: 'center'
                    }}>
                        <Box
                            component="img"
                            sx={{
                                mr: 1,
                                width: { xs: 106, md: 230 },
                                //   display: { xs: 'none', md: 'flex' },
                            }}
                            onClick={() => {
                                navigate(`/print-manual/${printerId}`)
                            }}
                            alt="Ready Form"
                            src="/assets/images/ready_form.png"
                        />
                        <Typography
                            noWrap
                            component="h6"
                            // href="/"
                            sx={{
                                fontFamily: 'monospace',
                                fontSize: { sx: 14, md: 24 },
                                fontWeight: 900,
                                color: 'black',
                                textDecoration: 'none',
                                justifyContent: 'center',
                                textAlign: 'center'
                            }}
                        >
                            {translations.ready_form}
                        </Typography>
                    </Grid>
                    <Grid item sx={{
                        width: { xs: '50%', md: '25%' },
                        textAlign: 'center'
                    }}>
                        <Box
                            component="img"
                            sx={{
                                mr: 1,
                                width: { xs: 106, md: 230 },
                                //   display: { xs: 'none', md: 'flex' },
                            }}
                            onClick={() => {
                                navigate(`/payment-center/${printerId}`)
                            }}
                            alt="payment and service"
                            src="/assets/images/payment_center.png"
                        />
                        <Typography
                            noWrap
                            component="h6"
                            sx={{
                                fontFamily: 'monospace',
                                fontSize: { sx: 14, md: 24 },
                                fontWeight: 900,
                                color: 'black',
                                textDecoration: 'none',
                                justifyContent: 'center',
                                textAlign: 'center'
                            }}
                        >
                            {translations.payment_center}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    p={2}
                >
                    <Box
                        sx={{
                            maxWidth: '95%',
                            maxHeight: 'none',
                            backgroundColor: '#570B6D',
                            paddingBlock: '15px',

                            margin: '25px',
                            borderRadius: '25px'

                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"

                        >

                            <Typography
                                noWrap
                                component="h3"
                                // href="/"
                                sx={{
                                    mr: 2,
                                    // display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'monospace',
                                    fontSize: 30,
                                    fontWeight: 400,
                                    color: 'white',
                                    textDecoration: 'none',
                                    justifyContent: 'center',
                                    marginBottom: 3
                                }}
                            >
                                {translations.how_do_i_print}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            marginInline='10px'

                        >
                            <Typography
                                noWrap
                                component="h6"
                                // href="/"
                                sx={{
                                    mr: 2,
                                    // display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'monospace',
                                    fontSize: { sx: 10, md: 20 },
                                    fontWeight: 400,
                                    color: 'white',
                                    textDecoration: 'none',
                                    justifyContent: 'center',
                                    // paddingInline: { sx: '160px', md: '200px' },
                                }}
                            >
                                {translations.printing_can_be_done_through_only_three_steps}
                            </Typography>
                            <Box
                                component="img"
                                sx={{
                                    mr: 1,
                                    width: 40,
                                    marginInline: '50%',
                                    marginBlock: '10px'
                                    //   display: { xs: 'none', md: 'flex' },
                                }}
                                alt="payment center"
                                src="/assets/images/print_btn.png"
                            />

                            <Typography
                                component="h6"
                                align='center'
                                // href="/"
                                sx={{
                                    mr: 2,
                                    // display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'monospace',
                                    fontSize: { sx: 12, md: 15 },
                                    fontWeight: 500,
                                    color: 'white',
                                    textDecoration: 'none',
                                    justifyContent: 'center',
                                }}
                            >
                                {translations.choice_the_printing_type}
                            </Typography>
                            <Box
                                component="img"
                                sx={{
                                    mr: 1,
                                    width: 40,
                                    marginInline: '50%',
                                    marginBlock: '10px'
                                    //   display: { xs: 'none', md: 'flex' },
                                }}
                                alt="payment center"
                                src="/assets/images/form_btn.png"
                            />
                            <Typography
                                component="h6"
                                align='center'
                                // href="/"
                                sx={{
                                    // display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'monospace',
                                    fontSize: { sx: 12, md: 15 },
                                    fontWeight: 500,
                                    color: 'white',
                                    textDecoration: 'none',
                                    justifyContent: 'center',
                                }}
                            >
                                {translations.click_print}
                            </Typography>
                            <Box
                                component="img"
                                sx={{
                                    mr: 1,
                                    width: 40,
                                    marginInline: '50%',
                                    marginBlock: '10px'
                                    //   display: { xs: 'none', md: 'flex' },
                                }}
                                alt="payment center"
                                src="/assets/images/paper.png"
                            />
                            <Typography
                                component="h6"
                                align='center'
                                // href="/"
                                sx={{
                                    fontFamily: 'monospace',
                                    fontSize: { sx: 12, md: 15 },
                                    fontWeight: 500,
                                    color: 'white',
                                    textDecoration: 'none',
                                    justifyContent: 'center',
                                }}
                            >
                                {translations.take_your_paper_from_the_self_service_machine}
                            </Typography>
                        </Grid>
                    </Box>

                </Grid>
            </Grid>

        );
    } else {
        return (<Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            gap="52px"

        >
            <Typography
                component="h3"
                // href="/"
                sx={{
                    mr: 2,
                    // display: { xs: 'none', md: 'flex' },
                    fontFamily: 'roboto',
                    fontSize: { xs: '32px', md: '56px' },
                    fontWeight: 'bold',
                    color: '#920497',
                    textDecoration: 'none',
                    justifyContent: 'center',
                    marginTop: 5,
                    marginInline: { sm: 20, md: 20 }
                }}
            >
                {translations.link_expired}
            </Typography>
            <Box
                component="img"
                sx={{
                    mr: 1,
                    width: { xs: 350, md: 440 },
                }}
                alt="error"
                src="/assets/images/error-vector.svg"
            />
            <Typography
                component="h3"
                // href="/"
                sx={{
                    mr: 2,
                    // display: { xs: 'none', md: 'flex' },
                    fontFamily: 'roboto',
                    fontSize: { xs: '16px', md: '24px' },
                    lineHeight: { xs: '20px', md: '24px' },
                    padding: '0 24px',
                    fontWeight: 300,
                    textAlign: 'center',
                    color: '#1a1a1a',
                    textDecoration: 'none',
                    justifyContent: 'center',
                    marginInline: { sm: 20, md: 0 },
                    width: '100%'
                }}
            >
                {translations.link_expired_detail}
            </Typography>
        </Grid>)
    }

};

export default PrintOptionWithTimestamp;
