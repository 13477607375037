import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import QrCode from './QrCode';
import ManualQrCode from './ManualQrCode';

const Form = Loadable(lazy(() => import('./QrCode')));

const QrCodeRoutes = [
  { path: '/qr-code/:printerId', element: < ManualQrCode type="qr"/> },
  { path: '/manual-qr-code/:printerId', element: < ManualQrCode type="manual-qr"/> },
];
    
export default QrCodeRoutes;
